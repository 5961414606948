<!DOCTYPE html>
<footer>
    <div class="left">
        <div class="copyright">
            {{'FOOTER.COPYRIGHT.LOCATION' | translate}}
        </div>
        <div class="contact">{{'FOOTER.CONTACT.STREET' | translate}} <br>
            {{'FOOTER.CONTACT.PLACE' | translate}}<br>
            {{'FOOTER.CONTACT.COUNTRY' | translate}}<br>
            <a href="mailto:bexysabetattoos@gmail.com" class="mailto"> {{'FOOTER.CONTACT.EMAIL' | translate}}</a> <br>
          <a class="mailto" href="tel:015737014586"> {{'FOOTER.CONTACT.PHONE' | translate}} </a>
        </div>
        <p id="links">
            <a class="bold-links" routerLink="/imprint" routerLinkActive="active">{{'FOOTER.IMPRINT.TITLE_IMPRINT' |
                translate}}</a> <br>
            <a class="bold-links" routerLink="/copyright" routerLinkActive="active">{{'FOOTER.IMPRINT.TITLE_DATA' |
                translate}}</a>
        </p>
    </div>
    <div class="right">
        <span class="opening-hours">{{'FOOTER.INFO.OPENING' | translate}}</span>
        <p class="appointments">{{'FOOTER.INFO.APPOINTMENTS' | translate}} </p>
        <p class="consent-iframe"></p>
        <ng-container *ngIf="consent">
            <div id="maps"> <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1223.72065152018!2d9.954439563672823!3d52.16266946757272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47baaf413dee25ad%3A0x4d65e8b16c63b00a!2sb%20l%20a%20n%20k%20%E2%80%93%20Tattoo%20Studio!5e0!3m2!1sde!2sde!4v1636812115588!5m2!1sde!2sde"
                    width="500" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe></div>
        </ng-container>
        <ng-container *ngIf="!consent">
            <div class="maps-container">
                <img src="assets/img/maps.png"  class="maps-picutre">
            </div>
                <p class="maps-consent">
                    <span>
                    Durch Aktivierung der Karte werden von <br> Google Maps Cookies gesetzt, Ihre IP-​Adresse <br> 
                    gespeichert und Daten in die USA übertragen.

                    <br> Mehr dazu in unserem <a routerLink="/copyright" class="copyright"> Datenschutzhinweis </a> <br>
                    </span>
                <button (click)="setCookie()" class="btn btn-dark">Google Maps aktivieren </button> <br>
                <button class="btn btn-dark">
                    <a href="https://goo.gl/maps/hTYV1RJuNWp5iiRT7" class="maps-link"> Standort in Google Maps
                        aufrufen</a></button>
                    </p>

        </ng-container>
    </div>
</footer>
