<div class="home">
    <ul>
        <h1 class="title">Pflegehinweise</h1>

        <li>
            <h4>{{'COPYRIGHT.GENERAL_TITLE' | translate}}</h4>
        </li>
        <li>
            Eine Tätowierung ist für immer. Es gibt zwar mitlerweile Möglichkeiten, Tätowierungen zu entfernen, jedoch
            sind diese sehr teuer, sehr schmerzhaft und man sieht danach selten so aus, als hätte man nie ein Tattoo
            gehabt. <br><br>
            Ein Tattoo sollte niemals über der Gesundheit stehen, wenn du eine Verletzung, Krankheit, Erkältung o.ä.
            hast, teile das bitte rechtzeitig mit, damit wir das gemeinsam bewerten können. <br><br>
            Mit einer frischen Tätowierung muss man gewisse Dinge beachten, um zu einem optimalen, abgeheilten Ergebnis
            zu gelangen. Dazu weiter unten mehr. <br><br>
            Der Tätowierer sorgt für alles was innerhalb des Studios passiert, danach ist der Kunde verantwortlich.
            Durch mangelhafte Pflege, falsches Verhalten oder unhygienisches Verhalten kann ein eigentlich schönes
            Tattoo noch im nachhinein schlecht aussehen/abheilen.
        </li>
        <h4>Vor dem Tattoo</h4>
        <li>
            Komm fit und ausgeschlafen zu deinem Termin. Es ist auch ratsam, schon etwas gegessen zu haben vor dem
            Termin. Trinke 24h vor deinem Termin keinen Alkohol und konsumiere keine anderen Drogen. <br><br>
            Beachte, dass eine Tätowierung ein Eingriff in deine körperliche Unversehrtheit ist. Das bedeutet, eine
            Tattoo Session kann auch anstrengend sein, plane daher gerade bei größeren Sitzungen schon im Vorfeld deinen
            Rückweg.
        <li>
            <h4>Während des Tätowierens</h4>

        </li>
        <li>
            ...sollte klar sein, das man ruhig liegt.
            „Zucker“ von Kunden sind entgegen der verbreiteten Angst sehr selten. <br><br>
            Schmerz empfindet jeder Mensch anders, die meisten Kunden können jedoch mehrere Stunden verkraften. <br><br>
            Oft hört man von Kunden, die gerade ihr ersten Tattoo bekommen haben, es war „aushaltbar“
            Es kommt auf Tattootechnik, Körperstelle und Tattoogröße an. <br><br>
            Fasse unter keinen Umständen in die frische Tätowierung, weder während, noch nach dem Tätowieren!

        </li>
        <h4>Nach dem Tätowieren</h4>
        <li>
            Ein Tattoo ist eine offene Wunde, deswegen versuch grundlegenden Hygieneregeln zu beachten.
            Zum Beispiel: <br><br>

            -bezieh dein Bett neu bevor du dich mit deinem frischen Tattoo hinein legst <br><br>
            -trage frisch gewaschene Kleidung <br><br>
            -versuche Dein frisches Tattoo so gut es geht zu schützen, <br><br>
            vor zb. Tierhaaren, Tieren allgemein, Druck, Reibung, Salz- und Chlorwasser, UV-Strahlung
            -Vermeide am Anfang direkte Sonneneinstrahlung. Bedecke dein Tattoo wenn du in die Sonne gehst. Verwende auf
            dem frischen Tattoo nur Tattoo Sonnencreme.<br><br>
            -Versuche die erste Woche nach deinem Tattoo kein Sport zu machen. Das ist natürlich auch abhängig von der
            Stelle und Größe. Bei kleinen Tattoos die schnell abheilen kannst du schon eher starten, bei großen Tattoos
            mit viel schwarz, würde ich länger warten.
        </li>
        <h4>Pflegehinweise</h4>
        <li>
            Es kann passieren, dass sich unter der Folie Wundwasser/Blut/Farbe sammelt. Wenn es allerdings komplett
            zugesifft ist, einmal Folie abmachen, unter lauwarmen Wasser mit sauberen Händen abspülen und trocken
            tupfen(!).<br><br>
            Es kann passieren, dass in den ersten Tagen deine Lymphknoten etwas anschwellen und schmerzen.
            Einige Tage nach dem tätowieren hört der Schmerz auf.<br><br>
            Solltest du trotzdem Fragen oder Sorge haben, das etwas nicht stimmt, schreib mir.<br><br>
            Creme Dein tattoo 2-3 täglich mit einer Tattoo Creme ein (Zum Beispiel Hustle Butter o. Pegasus). Alternativ
            geht auch eine Zinkfreie Panthenol Salbe. Creme so, dass alles einzieht.
            Lieber oft mit wenig Creme, als seltener und dafür zu viel Creme.<br><br>
            Creme aufjedenfall so lange, bis die Schorfkruste sich komplett gelöst hat. Danach reicht einmal täglich,
            bis auch der Silberschimmer, der nach dem Schorf kommt, auf deinem Tattoo verschwunden ist .
            .Vermeide Reibung und Druck. Trage an der Stelle deines frischen Tattoos keine enge Kleidung und tupf dich
            nach dem Duschen mit einem frischen Handtuch vorsichtig ab. Nicht rubbeln!<br><br>
            Kein Chlor- oder Salzwasser und Solarium bis 6 Wochen nach dem Tattoo. Solange dein Tattoo noch schorft und
            die Kruste nicht vollständig gelöst ist auch keine Badewanne, Salzwasser oder Sauna, damit die Wunde/das
            Tattoo nicht aufweicht.<br><br>
            Du bekommst nach unserem Termin eine (Klebe-)Folie auf dein Tattoo. Die kannst du zwischen 3-4 Tagen drauf
            lassen. Wenn die Folie sich vorher schon ablöst oder zu viel Wundwasser austritt dann entferne die Folie
            schon vorher.<br><br>
            Wasch dein Tattoo erst mit lauwarmen Wasser ab, dann mit kaltem Wasser (damit sich die Poren öffnen und
            schließen)<br><br>
            Manche Tattoos werden nur in Frischhaltefolie eingewickelt, hier verhält es sich mit der Pflege genau so.
            Lasse die Folie über Nacht auf dem Tattoo, entferne sie am nächsten Tag vor dem Duschen und tupfe das Tattoo
            nach dem Duschen trocken und creme es ein.<br><br>
            Ist die Folie vor dem Schlafen voll mit Blut und/oder Farbe, wechsel sie einmal.
        </li>
        <h4>Nachstechen</h4>
        <li>
            muss man bei vielen Tattoos garnicht!
            Es ist normal, das Tattoos nicht so schwarz abheilen, wie sie gestochen wurden sind.<br><br>
            Wenn beim Abheilprozess Farbe rausgeht, zb. durch Schorf, die Hautgegebenheit oder weil die Farbe partiell
            nicht tief genug eingebracht wurde, kann man nachstechen.<br><br>
            Auch hier muss man im Einzelfall entscheiden, nachstechen ist i.d.R. kostenfrei.
        </li>
        <h4>Risiken</h4>
        <li>
            Laut dem US National Library of Medicine National Institutes of Health haben 0,5-6% der Tätowierten Probleme
            beim abheilen ihres Tattoos.<br><br>
            Die „häufigsten“ Komplikationen sind:<br><br>
            -Blowout (Auslaufen der Farbe unter der Haut, verschiedene Ursachen: Druck, Reibung, Zu tief gestochen,
            Bindegewebsschwäche)<br><br>
            -Entzündung (Oft mit einfacher, Apothekenpflichtiger Creme behandelt)<br><br>
            -Allergische Reaktionen<br><br>
            Eine Entzündung kann sich u.a. so äußern, dass das Tattoo Tage nach dem Stechen noch warm ist, eitert, wieder schmerzt, verfärbt ist.
        </li>
        <h4>Anzahlung</h4>
        <li>
            Wir vereinbaren für unsere Termine Anzahlungen. Diese dienen uns als Sicherheit, dass der Termin
            stattfindet.<br><br>
            Wir sind beim Termin im Studio, wenn du nicht kommst sitzen wir da und hätten den Tag anders nutzen können..
            Sei kein Arsch, und sag früh genug ab. Dafür haben wir Verständnis.<br><br>
            Wenn du deinen Termin einfach nicht Wahrnimmst oder kürzer als 48h vor dem Termin absagst, verfällt deine
            Anzahlung.

        </li>
        <h4>Ach Dings...</h4>
        <li>
            Die Tante deines Nachbarn hat mal in einer Zeitung das Wort Tattoo gelesen. Das macht sie nicht zu einem
            Tätowierer. Höre bitte in Tattoosachen nur auf Tätowierer!<br><br>
            Eine Entzündung, Blowout o.ä. ist vom Tätowierer zum beurteilen. Auch, ob und wie dann nachgestochen werden
            muss!<br><br>
            Jetzt.. Viel Spaß mit deinem Tattoo!
        </li>
    </ul>
</div>