<body>
    <div class="wrapper">
        <div class="title">{{'IMPRINT.TITLE' | translate}}</div>
        <p class="first">
            {{'IMPRINT.NAME' | translate}} <br>
            {{'FOOTER.CONTACT.STREET' | translate}} <br>
            {{'FOOTER.CONTACT.PLACE' | translate}} <br>
        </p>
        <p class="first">
         <a href="mailto:junkietattoos@gmail.com" class="mailto">   {{'FOOTER.CONTACT.EMAIL' | translate}} </a> <br>
         <a class="mailto" href="tel:051218754455"> {{'FOOTER.CONTACT.PHONE' | translate}} </a>
        </p>
        <button type="button" class="btn btn-secondary"  routerLink="/contact"  routerLinkActive="active"> {{'IMPRINT.APPOINTMENT' | translate}} </button>
    </div>
</body>