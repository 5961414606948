<body>
    <div class="container-lg main-container">
      <div class="row justify-content-center">
        <div class="col-auto">
          <h2 class="headline">{{'CONTACT.APPOINTMENT_REQUEST' | translate}}</h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <p class="info">{{'CONTACT.APPOINTMENT_INFO' | translate}}</p>
        </div>
      </div>
      <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="px-0">
        <div class="row">
          <div class="col-sm-12 col-md-2">
            <div class="mb-3">
              <label class="form-label" for="Pronouns">{{'CONTACT.PRONOUNS' | translate}}</label>
              <input id="Pronouns" type="text" formControlName="pronouns" class="full" name="name">
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="mb-3">
              <label class="form-label" for="prename">{{'CONTACT.FIRST_NAME' | translate}}</label>
              <input id="prename" type="text" formControlName="firstName" class="full" name="email" required>
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="mb-3">
              <label class="form-label" for="lastName">{{'CONTACT.LAST_NAME' | translate}}</label>
              <input id="lastname" type="text" formControlName="lastName" class="full" required>
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="mb-3">
              <label class="form-label" for="email">{{'CONTACT.EMAIL' | translate}}</label>
              <input id="email" type="text" formControlName="email" class="full" required>
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="mb-3">
              <label class="form-label" for="instagram">{{'CONTACT.INSTAGRAM' | translate}}</label>
              <input id="name" type="text" formControlName="instagram" class="full" required>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label class="form-label" for="phonenumber">{{'CONTACT.PHONE' | translate}}</label>
              <input id="name" type="text" formControlName="phonenumber" class="full" required>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label class="form-label" for="description">{{'CONTACT.DESCRIPTION' | translate}}</label>
              <input id="name" type="textarea" formControlName="description" class="item" class="full" required>
            </div>
          </div>
  
          <div class="col-12">
            <div class="mb-3">
              <label class="form-label" for="bodyPart">{{'CONTACT.BODYPARTS' | translate}}</label>
              <div *ngFor="let data of bodyparts" class="item" required (click)="checkedParts = true">
                <input type="checkbox" (change)="onChangeBodyPart(data.bodypart, $event.target.checked)" />
                {{ data.bodypart }}<br />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label class="form-label" for="preferredDay">{{'CONTACT.PREFERRED_DAY' | translate}}</label>
              <div *ngFor="let data of days" class="item" required (click)="checkedDays = true">
                <input type="checkbox" (change)="onChangeDays(data.preferredDay, $event.target.checked)" />
                {{ data.preferredDay }}<br />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label class="form-label" for="preferredTime">{{'CONTACT.PREFERRED_TIME' | translate}}</label>
              <div *ngFor="let data of times" class="item" required>
                <input type="radio" formControlName="preferredTime" [value]='data' required />
                {{ data }}<br />
              </div>
            </div>
          </div>
  
          <div class="col-12">
            <div class="mb-3">
              <label class="form-label" for="projectInformation">{{'CONTACT.MORE_INFO' | translate}}</label>
              <div *ngFor="let data of options" class="item">
                <input type="checkbox" (change)="onChangeOptions(data.projectInformation, $event.target.checked)" />
                {{ data.projectInformation }}<br />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label class="form-label" for="returningCustomer">{{'CONTACT.RECURRING_CUSTOMER' | translate}}</label>
              <div class="mb-2">
                <small class="form-text text-muted">{{'CONTACT.DESIGN' | translate}}</small>
              </div>
              <textarea id="returningCustomer" type="textarea" formControlName="returningCustomer"
                class="returningCustomer"> </textarea>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label class="form-label" for="additionalTattooInformation">Sonstige Anmerkung:</label>
              <textarea id="additionalTattooInformation" type="textarea" formControlName="additionalTattooInformation"
                class="returningCustomer"> </textarea>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <input id="accepted" type="checkbox" class="item" (click)="checked = true"
                [disabled]="!myForm?.valid || !checkedParts || !checkedDays">
              <span class="agree">
                {{'CONTACT.AGREE_1' | translate}}
              </span>
              <ul class="pl-1 margin-left">
                <li class="fw-bold"> {{'CONTACT.AGREE_2' | translate}}</li>
                <li class="fw-bold"> {{'CONTACT.AGREE_3' | translate}}</li>
                <li class="fw-bold"> {{'CONTACT.AGREE_4' | translate}}</li>
                <li class="fw-bold"> {{'CONTACT.AGREE_5' | translate}}</li>
              </ul>
            </div>
          </div>
          <div [class.not-allowed]="!myForm?.valid || !checked || !checkedParts || !checkedDays" class="send-appointment">
            <button class="btn btn-secondary margin-left" type="submit"
              [class.disabled]="!myForm?.valid || !checked || !checkedParts || !checkedDays"
              [class.disableButton]="disableButton"> {{'CONTACT.SEND' | translate}}</button>
            <span class="required margin-left"> {{'CONTACT.REQUIRED' | translate}}</span>
          </div>
          <div *ngIf="showMsgSuccess" class="alert success">
            {{'CONTACT.SUCCESS' | translate}}
          </div>
          <div *ngIf="showMsgError" class="alert error">
            {{'CONTACT.ERROR' | translate}}
          </div>
        </div>
      </form>
    </div>
  </body>