<div class="home">
    <ul>
        <h1 class="title">Datenschutz</h1>

        <li>
            <h4>{{'COPYRIGHT.GENERAL_TITLE' | translate}}</h4>
        </li>
        <li>
            {{'COPYRIGHT.GENERAL_1' | translate}}
            {{'COPYRIGHT.GENERAL_2' | translate}}
            {{'COPYRIGHT.GENERAL_3' | translate}}
        </li>
        <h4>{{'COPYRIGHT.USAGE_TITLE' | translate}}</h4>
        <li>
            {{'COPYRIGHT.USAGE_1' | translate}}
            {{'COPYRIGHT.USAGE_2' | translate}}
        <li>
            <h4>{{'COPYRIGHT.COOKIES_TITLE' | translate}}</h4>

        </li>
        <li>
            {{'COPYRIGHT.COOKIES_1' | translate}}
            {{'COPYRIGHT.COOKIES_2' | translate}}
        </li>
        <h4>{{'COPYRIGHT.INFO_TITLE' | translate}}</h4>
        <li>
            {{'COPYRIGHT.INFO_1' | translate}}
            {{'COPYRIGHT.INFO_2' | translate}}
        </li>
        <h4>{{'COPYRIGHT.ABOUT_US_TITLE' | translate}}</h4>
        <li>
            {{'COPYRIGHT.ABOUT_US_1' | translate}}
        </li>
        <h4>5. Hinweis zur Datenweitergabe in die USA</h4>
        <li>
            Auf unserer Website sind Tools von Unternehmen mit Sitz in anderen nicht-EU Ländern eingebunden (z.B.
            Google Maps). Wenn
            diese
            Tools aktiv sind,werden Ihre personenbezogenen Daten an die Server der jeweiligen Unternehmen
            weitergegeben.Google Maps gehört zu Google LLC und übermittelt Daten in die USA, also in ein
            Nicht-europäisches Ausland, mit anderen Datenschutzgesetzen. Mit dieser Datenschutz erklären weisen wir
            darauf hin, dass die USA kein sicherer
            Drittstaat im Sinne des EU-​Datenschutzrechts sind. US-​Unternehmen sind dazu verpflichtet,
            personenbezogene Daten an
            Sicherheitsbehörden herauszugeben, ohne dass Sie als Geeschädigter hiergegen gerichtlich vorgehen
            könnten.
            Es kann daher nicht ausgeschlossen werden, dass US-​Behörden (z.B. Geheimdienste) Ihre auf den
            jeweiligen Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und/oder dauerhaft
            speichern. Darauf haben wir keinen Einfluss.
        </li>
    </ul>
</div>