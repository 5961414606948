<div class="home">

    <div> <img src="assets/img/removedbg.png" class="title" (change)="onResize(event)" *ngIf="big">
        <div> <img src="assets/img/butterfly.png" class="title butterfly" *ngIf="small"> </div>
    </div>
    <div class="buttons"> 
        <button type="button" class="btn btn-dark btns margin" routerLink="/contact" routerLinkActive="active">
            {{'HOME.BUTTON'
            |
            translate}} </button>
        <button type="button" class="btn btn-dark btns" routerLink="/care" routerLinkActive="active"> Pflegehinweise
        </button>
        <div class #paypalRef></div>

    </div>

</div>
